(() => {

  $(window).on('scroll', function () {
    var scroll_size = $(this).scrollTop();
    var header_height = $('#header').height();
    if (scroll_size > header_height) {
      $('.js-CvArea').css('bottom', '0');
      $('body').css('padding-bottom', $('.js-CvArea').height());
    } else {
      $('.js-CvArea').css('bottom', -$('.js-CvArea').height());
    }
  });

  $(function () {
    $('.js-tab').on('click', function () {
      var index = $('.js-tab').index(this);
      $('.js-tab').removeClass('is-active');
      $(this).addClass('is-active');
      $('.js-tabBody').css('display', 'none');
      $('.js-tabBody').eq(index).css('display', 'block');
    });
  });

  $('.js-inview').on('inview', function(event, isInView) {
    if (isInView) {
      $(this).addClass("is-inview")
    }
  });
  $('.js-inviewList').on('inview', function(event, isInView) {
    if (isInView) {
      if($(this).find('li').length > 0){
        $(this).find('li').each(function(i) {
          $(this).delay(100 * i).queue(function() {
            $(this).addClass('is-inview').dequeue();
          });
        });
      }
    }
  });
  $('.js-inviewText').on('inview', function(event, isInView) {
    if (isInView) {
      if($(this).find('li').length > 0){
        $.when(
          $(this).find('li').each(function(i) {
            $(this).delay(100 * i).queue(function() {
              $(this).addClass('is-inview').dequeue();
            });
          })
        ).done(function(){
          $('.js-inviewTextAfter').addClass('is-show');
        });
      }
    }
  });

  $('.js-HeaderMenu-button').on('click', function () {
    $(this).toggleClass('is-active');
    $('.js-Header').toggleClass('is-active');
    $('.js-HeaderMenu').toggleClass('is-active');
    $('.js-HeaderMenuBg').toggleClass('is-active');
  });
  $('.js-HeaderMenuBg').on('click', function () {
    $('.js-Header').removeClass('is-active');
    $('.js-HeaderMenu-button').removeClass('is-active');
    $('.js-HeaderMenu').removeClass('is-active');
    $('.js-HeaderMenuBg').removeClass('is-active');
  })

})()